@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IRANSans";
  font-weight: 200;
  src: url("./fonts/fanum/IRANSans(FaNum)_UltraLight.ttf") format("ttf");
}

@font-face {
  font-family: "IRANSans";
  font-weight: 300;
  src: url("./fonts/fanum/IRANSans(FaNum)_Light.ttf") format("ttf");
}

@font-face {
  font-family: "IRANSans";
  font-weight: 400;
  src: url("./fonts/fanum/IRANSans(FaNum).ttf");
}

@font-face {
  font-family: "IRANSans";
  font-weight: 500;
  src: url("./fonts/fanum/IRANSans(FaNum)_Medium.ttf") format("ttf");
}

@font-face {
  font-family: "IRANSans";
  font-weight: 700;
  src: url("./fonts/fanum/IRANSans(FaNum)_Bold.ttf") format("ttf");
}

@font-face {
  font-family: "IRANSans";
  font-weight: 900;
  src: url("./fonts/fanum/IRANSans(FaNum)_Black.ttf") format("ttf");
}

/* Iran Yekanx */

@font-face {
  font-family: "IRANYekanX";
  font-weight: 200;
  src: url("./fonts/yekan/IRANYekanX-Heavy.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 100;
  src: url("./fonts/yekan/IRANYekanX-Thin.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 200;
  src: url("./fonts/yekan/IRANYekanX-UltraLight.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 300;
  src: url("./fonts/yekan/IRANYekanX-Light.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 400;
  src: url("./fonts/yekan/IRANYekanX-Regular.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 500;
  src: url("./fonts/yekan/IRANYekanX-Medium.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 600;
  src: url("./fonts/yekan/IRANYekanX-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 700;
  src: url("./fonts/yekan/IRANYekanX-Bold.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 800;
  src: url("./fonts/yekan/IRANYekanX-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 900;
  src: url("./fonts/yekan/IRANYekanX-Black.woff") format("woff");
}

@font-face {
  font-family: "IRANYekanX";
  font-weight: 1000;
  src: url("./fonts/yekan/IRANYekanX-ExtraBlack.woff") format("woff");
}

.swiper-pagination {
  pagination: absolute !important;
  bottom: 10px !important;
}

.swiper-wrapper {
  margin-bottom: 50px;
}

/* Use CSS classes to change the bullet color */
.swiper-pagination-bullet {
  background-color: #bdbdbd !important;
}

.swiper-pagination-bullet-active {
  background-color: #1ed760 !important;
}

.swiper-pagination-bullets-dynamic {
  position: absolute !important ;
  bottom: 70px !important;
}

.swiper-pagination-bullets-dynamic > .swiper-pagination-bullet{
  background-color: white !important;
  opacity: 100 !important;
}

.swiper-pagination-bullets-dynamic > .swiper-pagination-bullet-active{
  background-color: #1ed760 !important;
}

.react-player {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: "500px";
  border-radius: 15px !important; /* This will add border radius 15 px */
}

/* scroll bar design */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eaecf0;
  height: 100px;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d0d5dd;
}
